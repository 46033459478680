import React, {Component} from 'react';


import lang from '../../lang';

class Footer extends Component {
    topFunction = () => {
        window.scrollTo(0, 0)
      }

    render()
    {
        return(
            <footer class="w3l-footer">
            <div id="footers14-block" class="py-3">
              <div class="container">
                <div class="footers14-bottom text-center">
                  <div class="social">
                    <a href="https://www.facebook.com/ange.boutchoue" class="facebook"><span class="fa fa-facebook" aria-hidden="true"></span></a>
        
                    <a href="https://twitter.com/ange_boutchoue" class="twitter"><span class="fa fa-twitter" aria-hidden="true"></span></a>
        
                    <a href="#youtube" class="youtube"><span class="fa fa-youtube" aria-hidden="true"></span></a>
                  </div>
                  <div class="copyright mt-1">
                    <p>© 2020 A.B {lang.Droits} <a href="http://angeboutchoue.com/">Ange Boutchoue</a></p>
                  </div>
                </div>
              </div>
            </div>
        
            <button onClick={this.topFunction} id="movetop" title="Go to top">
              <span class="fa fa-angle-up" aria-hidden="true"></span>
            </button>
        
          </footer>
        )
    }
}




export default Footer;