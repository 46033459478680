import React, { Component } from 'react';

import lang from '../../lang/index'


//Import Images 
import IMG3 from '../../assets/images/3.jpg';
import NBA from '../../assets/images/nba.jpeg';
import IAMOB from '../../assets/images/Iamob.jpeg';
import COVID from '../../assets/images/Covid.jpeg';
import MEDCARE from '../../assets/images/Medcare.PNG';
import REDACTEUR from '../../assets/images/Redacteur.PNG';
import GETIDAN from '../../assets/images/Getidan.PNG';


class Home extends Component {

  state = {
    cvVisibility: false
  }

  cvVisibilityClicked = () => {
    this.setState({
      cvVisibility: true
    })
  }


  render() {
    return (
      <div>

        <section class="w3l-main-banner">
          <div class="companies20-content">
            <div class="companies-wrapper">
              <div class="item">
                <li>
                  <div class="slider-info banner-view">
                    <div class="banner-info container">
                      <h3 style={{ color: 'white' }} class="banner-text"><small > {lang.Hello} </small><br />
                        {lang.Desire}
                      </h3>
                      <p class="my-4 mb-5" style={{ color: 'white' }}>{lang.Full}</p><br />
                      <a href="#gallery" class="btn btn-primary theme-button mr-3">{lang.MyWork}</a>
                      <a href="#about.html" class="btn btn-outline-primary theme-button">{lang.Hire}</a>
                    </div>
                  </div>
                </li>
              </div>
            </div>
          </div>
        </section>

        <div class="w3l-about1 pt-5" id="about">
          <div class="container pt-lg-3">
            <h3 class="global-title">{lang.AboutMe}</h3>
            <div class="aboutgrids row">
              <div class="col-lg-6 aboutgrid2">
                <img src={IMG3} alt="about image" class="img-fluid" />
              </div>
              <div class="col-lg-6 aboutgrid1 my-lg-0 my-5">
                <h4>{lang.why}</h4>
                <p>{lang.honestly}</p>
                <p>{lang.otherhand} </p>

                <a style={{ marginBottom: '20px' }} onClick={this.cvVisibilityClicked} class="btn btn-outline-primary p-3">{lang.download} </a>
                <br />


                {this.state.cvVisibility ?

                  <div style={{ display: 'flex' }}>
                    <a class="btn btn-outline-primary p-3" target='_blank' href='http://platform.airsqreen.com:81/uploads/Desire_English_CV.pdf' download >EN</a>
                    <a class="btn btn-outline-primary p-3" target='_blank' href='http://platform.airsqreen.com:81/uploads/Desire_French_CV.pdf' download style={{ marginLeft: '15px' }}>FR</a>
                    <a class="btn btn-outline-primary p-3" target='_blank' href='http://platform.airsqreen.com:81/uploads/Desire_Turkish_CV.pdf' download style={{ marginLeft: '15px' }}>TR</a>
                  </div>
                  :

                  null

                }


              </div>


            </div>
          </div>

          <div class="aboutbottom py-5" style={{ marginTop: '50px' }}>
            <div class="container py-lg-3">
              <div class="bottomgrids row">
                <div class="col-lg-3 col-md-6 bottomgrid1 mt-lg-0 mt-0">
                  <h4>{lang.webP}</h4>
                  <p> {lang.refers}
                  </p>
                </div>
                <div class="col-lg-3 col-md-6 bottomgrid1 mt-md-0 mt-5">
                  <h4>{lang.Visual} </h4>
                  <p>{lang.Shape} </p>
                </div>
                <div class="col-lg-3 col-md-6 bottomgrid1 mt-lg-0 mt-5">
                  <h4>{lang.Network}</h4>
                  <p>{lang.Prevent}</p>
                </div>
                <div class="col-lg-3 col-md-6 bottomgrid1 mt-lg-0 mt-5">
                  <h4>{lang.Data}</h4>
                  <p>{lang.unify} </p>
                </div>
                <div class="clearfix"> </div>
              </div>
            </div>
          </div>
        </div>

        <section class="w3l-index6" id="service">
          <div class="features-with-17_sur py-5">
            <div class="container py-lg-3">
              <h3 class="global-title my-title">{lang.MyServis} </h3>
              <div class="features-with-17-top_sur">
                <div class="row">
                  <div class="col-lg-4 col-md-6 mt-md-0 mt-4">
                    <div class="features-with-17-right-tp_sur">
                      <div class="features-with-17-left1">
                        <span class="fa fa-laptop" aria-hidden="true"></span>
                      </div>
                      <div class="features-with-17-left2">
                        <h6><a href="#url">{lang.WebSite}</a></h6>
                        <p> {lang.Iammaking}</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-6 mt-md-0 mt-5">
                    <div class="features-with-17-right-tp_sur">
                      <div class="features-with-17-left1">
                        <span class="fa fa-mobile" aria-hidden="true"></span>
                      </div>
                      <div class="features-with-17-left2">
                        <h6><a href="#url"> {lang.Mobile}</a></h6>
                        <p>{lang.making}  </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 offset-md-3 offset-lg-0 col-md-6 mt-lg-0 mt-5">
                    <div class="features-with-17-right-tp_sur">
                      <div class="features-with-17-left1">
                        <span class="fa fa-bar-chart" aria-hidden="true"></span>
                      </div>
                      <div class="features-with-17-left2">
                        <h6><a href="#url">{lang.Data}</a> </h6>
                        <p> {lang.Combine} </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="w3l-services2">
          <div class="features-with-17_sur">
            <div class="container-fluid">
              <div class="row">
                <div class="col-lg-6 features-with-17-left_sur">

                </div>
                <div class="col-lg-6 my-lg-0 my-5 align-self-center features-with-17-right_sur">
                  <div class="features-with-17-right-tp_sur">
                    <div class="features-with-17-left1">
                      <span class="fa fa-laptop s4"></span>
                    </div>
                    <div class="features-with-17-left2">
                      <h6><a href="#url">{lang.Design}</a></h6>
                      <p>Html, css , javascript</p>
                    </div>
                  </div>
                  <div class="features-with-17-right-tp_sur">
                    <div class="features-with-17-left1">
                      <span class="fa fa-database s5"></span>
                    </div>
                    <div class="features-with-17-left2">
                      <h6><a href="#url"> {lang.Base}</a></h6>
                      <p>Mysql , Mssql server</p>
                    </div>
                  </div>
                  <div class="features-with-17-right-tp_sur">
                    <div class="features-with-17-left1">
                      <span class="fa fa-lock s3"></span>
                    </div>
                    <div class="features-with-17-left2">
                      <h6><a href="#url">{lang.Advance}</a></h6>
                      <p>Asp.Net mvc , Web/api , Docker , Hybernate solutions , Python </p>
                    </div>
                  </div>
                  <div class="features-with-17-right-tp_sur">
                    <div class="features-with-17-left1">
                      <span class="fa fa-codepen s2"></span>
                    </div>
                    <div class="features-with-17-left2">
                      <h6><a href="#url">{lang.Front}</a></h6>
                      <p>React.js</p>
                    </div>
                  </div>
                  <div class="features-with-17-right-tp_sur">
                    <div class="features-with-17-left1">
                      <span class="fa fa-mobile s3"></span>
                    </div>
                    <div class="features-with-17-left2">
                      <h6><a href="#url"> {lang.mob}</a></h6>
                      <p>Java, Xamarin , c# , React-native</p>
                    </div>
                  </div>
                  <div class="features-with-17-right-tp_sur">
                    <div class="features-with-17-left1">
                      <span class="fa fa-modx s2"></span>
                    </div>
                    <div class="features-with-17-left2">
                      <h6><a href="#url"> {lang.Science}</a></h6>
                      <p>Python - R - </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div class="w3l-gallery2" id="gallery">
          <div class="insta-picks py-5">
            <div class="container py-lg-5">
              <h3 class="global-title"> {lang.latest}</h3>
              <div class="row no-gutters masonry">
                <div class="col-md-4 col-sm-6 brick">
                  <a href={NBA} class="js-img-viwer d-block" data-caption="There are many variations of popular graphic design"
                    data-id="lion">
                    <img src={NBA} class="img-fluid insta-pic" alt="insta-pic" />
                    <div class="content-overlay"></div>
                    <div class="content-details fadeIn-top">
                      <h4>{lang.NBA}</h4>
                      <p>React-Native</p>
                    </div>
                  </a>
                </div>
                <div class="col-md-4 col-sm-6 brick">
                  <a href={IAMOB} class="js-img-viwer d-block" data-caption="There are many variations of popular graphic design"
                    data-id="camel">
                    <img src={IAMOB} class="img-fluid insta-pic" alt="insta-pic" />
                    <div class="content-overlay"></div>
                    <div class="content-details fadeIn-top">
                      <h4>{lang.Iamob} </h4>
                      <p>React Native</p>
                    </div>
                  </a>
                </div>
                <div class="col-md-4 col-sm-6 brick">
                  <a href={COVID} class="js-img-viwer d-block" data-caption="There are many variations of popular graphic design"
                    data-id="hippopotamus">
                    <img src={COVID} class="img-fluid insta-pic" alt="insta-pic" />
                    <div class="content-overlay"></div>
                    <div class="content-details fadeIn-top">
                      <h4>{lang.Covid}</h4>
                      <p>React Native</p>
                    </div>
                  </a>
                </div>
                <div class="col-md-4 col-sm-6 brick">
                  <a href={MEDCARE} class="js-img-viwer d-block" data-caption="There are many variations of popular graphic design"
                    data-id="koala">
                    <img src={MEDCARE} class="img-fluid insta-pic" alt="insta-pic" />
                    <div class="content-overlay"></div>
                    <div class="content-details fadeIn-top">
                      <h4>{lang.medcare} </h4>
                      <p>React.js - Asp.net web Api2</p>
                    </div>
                  </a>
                </div>
                <div class="col-md-4 col-sm-6 brick">
                  <a href={REDACTEUR} class="js-img-viwer d-block" data-caption="There are many variations of popular graphic design"
                    data-id="bear">
                    <img src={REDACTEUR} class="img-fluid insta-pic" alt="insta-pic" />
                    <div class="content-overlay"></div>
                    <div class="content-details fadeIn-top">
                      <h4>{lang.Script}</h4>
                      <p>React.js - Asp.net web Api2</p>
                    </div>
                  </a>
                </div>
                <div class="col-md-4 col-sm-6 brick">
                  <a href={GETIDAN} class="js-img-viwer d-block" data-caption="There are many variations of popular graphic design"
                    data-id="rhinoceros">
                    <img src={GETIDAN} class="img-fluid insta-pic" alt="insta-pic" />
                    <div class="content-overlay"></div>
                    <div class="content-details fadeIn-top">
                      <h4>{lang.Getid}</h4>
                      <p>React.js</p>
                    </div>
                  </a>
                </div>

              </div>
              <div class="text-center">
                <a href="https://www.linkedin.com/in/desire-boutchoue-541035153/" class="follow-insta-button btn btn-secondary p-3 mt-5" target="_blank">
                  {lang.Follow}
                </a>
              </div>
            </div>
          </div>
        </div>

        <section class="w3l-index5" id="intro">
          <div class="new-block top-bottom">
            <div class="container">
              <div class="middle-section text-center">
                <div class="section-width mb-5">
                  <h2> {lang.Imagination}</h2>
                </div>
                <a href="#small-dialog" class="popup-with-zoom-anim play-view text-center">
                  {/*  <span class="video-play-icon">
                     <span class="fa fa-play"></span>
                  </span>
                   */}
                </a>



              </div>
            </div>
          </div>
        </section>

        <section class="w3l-index2">
          <div class="main-w3 py-5" id="stats">
            <div class="container py-lg-3">
              <h3 class="global-title"> {lang.Stat}</h3>
              <div class="row main-cont-wthree-fea text-center">
                <div class="col-lg-3 col-6 mt-4">
                  <div class="grids-speci1">
                    <span class="fa fa-tasks" aria-hidden="true"></span>
                    <h3 class="title-spe">10+</h3>
                    <p> {lang.Project}</p>
                  </div>
                </div>
                <div class="col-lg-3 col-6 mt-4">
                  <div class="grids-speci1">
                    <span class="fa fa-users" aria-hidden="true"></span>
                    <h3 class="title-spe">50+</h3>
                    <p> {lang.Satisfied} </p>
                  </div>
                </div>
                <div class="col-lg-3 col-6 mt-4">
                  <div class="grids-speci1">
                    <span class="fa fa-code" aria-hidden="true"></span>
                    <h3 class="title-spe">1M+</h3>
                    <p>{lang.Lignes} </p>
                  </div>
                </div>
                <div class="col-lg-3 col-6 mt-4">
                  <div class="grids-speci1">
                    <span class="fa fa-smile-o" aria-hidden="true"></span>
                    <h3 class="title-spe">18k</h3>
                    <p>{lang.Happy}</p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </section>

        <section class="w3l-testimonials" id="testimonials">
          <div class="customers-6-content py-5">
            <div class="container py-lg-3">
              <h3 class="global-title global-white">{lang.HappyClients}</h3>
              <div class="customer row">
                <div class="col-lg-4 col-md-6 mt-md-3 mt-3">
                  <div class="card">
                    <img class="card-img-top img-responsive" src="assets/images/c1.jpg" alt="" />
                    <h3 class="card-title">Abdoulh</h3>
                    <div class="card-body">
                      <span class="fa fa-quote-left" aria-hidden="true"></span>
                      <p class="card-text">{lang.Rigouris} </p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6 mt-md-3 mt-5">
                  <div class="card">
                    <img class="card-img-top img-responsive" src="assets/images/c2.jpg" alt="" />
                    <h3 class="card-title">Florent</h3>
                    <div class="card-body">
                      <span class="fa fa-quote-left" aria-hidden="true"></span>
                      <p class="card-text">{lang.Ive}  </p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 offset-md-3 offset-lg-0 col-md-6 mt-md-3 mt-5">
                  <div class="card">
                    <img class="card-img-top img-responsive" src="assets/images/c3.jpg" alt="" />
                    <h3 class="card-title">Melissa</h3>
                    <div class="card-body">
                      <span class="fa fa-quote-left" aria-hidden="true"></span>
                      <p class="card-text">{lang.Very} </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="w3l-contact py-5" id="contact">
          <div class="container py-lg-3">
            <h3 class="global-title">{lang.Drop} </h3>
            <div class="row">
              <div class="col-md-8 contact-form">
                <form method="post">
                  <input type="text" class="form-control" name="w3lName" placeholder={lang.Name} /><br />
                  <input type="email" class="form-control" name="w3lSender" placeholder="E-mail" /><br />
                  <input type="text" class="form-control" name="w3lSubject" placeholder={lang.subject} /><br />
                  <textarea class="form-control" name="w3lMessage" placeholder="Your Message" style={{ height: '150px' }}></textarea><br />
                  <button class="btn btn-primary theme-button" type="submit">{lang.Send}</button>
                </form>
              </div>
              <div class="col-md-4 mt-md-0 mt-5 w3-contact-address">
                <b>{lang.adress}:</b>
                <p> Maltepe</p>
                <p>Findinklı Mh.Sarmaşik sk.</p>
                <p>No 116 d.1,</p>
                <p>Istanbul, {lang.Turk}</p>
                <br />
                <b>{lang.Phone}:</b>
                <p><a href="tel:+905392462904">+90 539 246 29 04 </a></p>

                <br />
                <b>Email:</b>
                <p><a href="mailto:angeboutchoue@mail.com">angeboutchoue@gmail.com</a></p>

              </div>
            </div>
          </div>
        </section>

        <section class="w3l-grid-quote py-5">
          <div class="container py-lg-3">
            <h6>{lang.avail}</h6>
            <h3> {lang.test}</h3>
            <a href="#contact" class="btn btn-primary theme-button">{lang.Get}</a>
            <a href="#url" class="btn btn-outline-primary theme-button ml-2">{lang.Hire}</a>
          </div>
        </section>

      </div>
    )
  }
};

export default Home;