import React from 'react';

import Header from './Header';
import Footer from './Footer';

const Layout = ({ children, changeLanguage, activeLanguage }) => {
    return (
        <div>

            <Header changeLanguage={changeLanguage}
                activeLanguage={activeLanguage} />

              <div>{children}</div>

            <Footer />


        </div>
    )
}

export default Layout;




