import React from 'react';
//Import Config & Dependenies
import {Link} from 'react-router-dom';
import lang from '../../lang';


const Header = ({changeLanguage, activeLanguage }) => (
<div>


<header class="w3l-header">
          <div class="hero-header-11">
            <div class="hero-header-11-content">
              <div class="container">
                <nav class="navbar navbar-expand-xl navbar-light py-sm-2 py-1 px-0">
                  <Link to='/' class="navbar-brand editContent" >AB</Link>
                  {/*  <!-- if logo is image enable this   
				<a class="navbar-brand" href="#index.html">
						<img src="image-path" alt="Your logo" title="Your logo" style="height:35px;" />
				</a> -->  */}
                  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon fa fa-bars"></span>
                  </button>

                  <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav ml-auto">
                      <li class="nav-item active">
                        <a class="nav-link" href="index.html">{lang.Home} <span class="sr-only">(current)</span></a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" href="#about"> {lang.About}</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" href="#service">{lang.services}</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" href="#gallery">{lang.Work}</a>
                      </li>
                      <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true"
                          aria-expanded="false">
                         {activeLanguage}
                         </a>
                        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                          <a class="dropdown-item" style={{cursor:'pointer'}} onClick={() => changeLanguage('EN') }>EN</a>
                          <a class="dropdown-item" style={{cursor:'pointer'}} onClick={() => changeLanguage('FR') }>FR</a>
                          <a class="dropdown-item" style={{cursor:'pointer'}} onClick={() => changeLanguage('TR') }>TR</a>
                        </div>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" href="#contact">{lang.Contact}</a>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </header>
        </div>


);

export default Header;