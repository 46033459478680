import React, { Component } from 'react';

import { Route, Switch } from 'react-router-dom';

import { withCookies } from 'react-cookie';
import lang from './lang';

import Layout from './component/layout/Layout';

// Import Pages
import Home from './pages/home/home';


class App extends Component {


  state = {
    activeLang: this.props.cookies.get('activeLang') || 'EN',
  };


  componentWillMount() {
    const { activeLang } = this.state;
    lang.setLanguage(activeLang);
  }

  changeLanguageHandler = (newLang) => {
    const { cookies } = this.props;
    cookies.remove('activeLang');
    const expires = new Date();
    expires.setTime(expires.getTime() + 7 * 24 * 60 * 60 * 1000);
    expires.toUTCString();
    cookies.set('activeLang', newLang, { expires });
    lang.setLanguage(newLang);
    this.setState({ activeLang: newLang });
  };


  render() {
    return (
      <Layout
        changeLanguage={this.changeLanguageHandler}
        activeLanguage={this.state.activeLang}
      >

        <Switch>
          <Route path="/" exact component={Home} />
        </Switch>
      </Layout>

    )
  }
}


export default withCookies(App);
